import React, { useLayoutEffect, useRef } from "react";
import GlassContainer from "./GlassContainer";
import reactLogo from "../assets/tech-icons/reactjs-icon.svg";
import githubLogo from "../assets/tech-icons/Gatsby.svg";
import nextLogo from "../assets/tech-icons/Next.js.svg";
import materialLogo from "../assets/tech-icons/Material UI.svg";
import herokuLogo from "../assets/tech-icons/Heroku.svg";
import graphQLLogo from "../assets/tech-icons/GraphQL.svg";
import jsonLogo from "../assets/tech-icons/JSON.svg";

import mongo2Logo from "../assets/tech-icons/mongodb2.svg";
import gatsbyLogo from "../assets/tech-icons/Gatsby.svg";
import awsLogo from "../assets/tech-icons/aws.svg";
import chromeLogo from "../assets/tech-icons/chrome.svg";
import cssLogo from "../assets/tech-icons/css.svg";
import firebaseLogo from "../assets/tech-icons/firebase.svg";
import gcpLogo from "../assets/tech-icons/gcp.svg";
import html5Logo from "../assets/tech-icons/html5.svg";
import javascriptLogo from "../assets/tech-icons/javascript.svg";
import nodejsLogo from "../assets/tech-icons/nodejs.svg";
import openaiLogo from "../assets/tech-icons/openai.svg";
import pythonLogo from "../assets/tech-icons/python.svg";
import pytorchLogo from "../assets/tech-icons/pytorch.svg";
import tailwindLogo from "../assets/tech-icons/tailwind.svg";
import tensorflowLogo from "../assets/tech-icons/tensorflow.svg";
import typescriptLogo from "../assets/tech-icons/typescript.svg";
import vscodeLogo from "../assets/tech-icons/vscode.svg";
import ProjectsList from "./ProjectsList";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useMouseParallax } from "../hooks/useMouseParallax";
import { useMediaQuery } from "../hooks/useMediaQuery";

gsap.registerPlugin(ScrollTrigger);

const techLogos = [
    {
        title: "1",
        lift: false,
        logo: reactLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
        size: "sm",
    },
    {
        title: "1",
        lift: false,
        logo: githubLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
        size: "sm",
    },
    {
        title: "1",
        lift: false,
        logo: awsLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
        size: "md",
    },
    {
        title: "1",
        lift: false,
        logo: vscodeLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-yellow-500 to-green-500",
        size: "sm",
    },
    {
        title: "1",
        lift: false,
        logo: nextLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500 !w-10 !h-10",
        style: {
            width: "2.5rem",
            height: "2.5rem",
        },
        // size: "sm",
    },
    {
        title: "1",
        lift: true,
        logo: cssLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
        size: "sm",
    },
    {
        title: "1",
        lift: false,
        logo: firebaseLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-yellow-500 to-green-500",
        size: "lg",
    },

    {
        title: "1",
        lift: true,
        logo: javascriptLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
    },
    // {
    //   title: "1",
    //   lift: false,
    //   logo: mongoLogo,
    //   alt: "logo",
    //   className: "bg-gradient-to-r from-purple-500 to-pink-500",
    //   size: "sm",
    // },
    {
        title: "1",
        lift: false,
        logo: typescriptLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
        size: "md",
    },
    {
        title: "1",
        lift: true,
        logo: nodejsLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
        size: "lg",
    },
    {
        title: "1",
        lift: false,
        logo: openaiLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
        size: "md",
    },
    {
        title: "1",
        lift: false,
        logo: mongo2Logo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
        size: "sm",
    },
    {
        title: "1",
        lift: true,
        logo: pythonLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
    },
    {
        title: "1",
        lift: false,
        logo: pytorchLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
        size: "md",
    },
    {
        title: "1",
        lift: true,
        logo: tailwindLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
    },
    {
        title: "1",
        lift: false,
        logo: gatsbyLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500 h-10 w-10",
        // size: "sm",
    },

    {
        title: "1",
        lift: true,
        logo: chromeLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
        size: "sm",
    },

    {
        title: "1",
        lift: true,
        logo: gcpLogo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
        size: "sm",
    },
    {
        title: "1",
        lift: false,
        logo: html5Logo,
        alt: "logo",
        className: "bg-gradient-to-r from-purple-500 to-pink-500",
        size: "lg",
    },
];

export default function TechStack({ children }) {
    const container = useRef(null);
    const isMobile = useMediaQuery("(max-width: 768px)");

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: container.current,
                    start: "top+=50% bottom",
                    end: "top+=20% top",
                },
            }).to(
                "#tech-stack-underline",
                {
                    delay: 2.5,
                    width: "100%",
                },
                "<"
            );

            gsap.set("#tech-cards-container", {
                autoAlpha: 0,
            });
            gsap.timeline({
                scrollTrigger: {
                    trigger: container.current,
                    start: "top+=65% bottom",
                    end: "top+=20% top",
                    scrub: 2,
                },
            }).to("#tech-cards-container", {
                autoAlpha: 0.9,
            });
        }, container);

        return () => ctx.revert();
    }, []);

    useMouseParallax({ element: "#tech-cards-container", disabled: isMobile });
    return (
        <div
            ref={container}
            className="relative flex h-screen w-screen items-center justify-center overflow-hidden"
            style={{
                backgroundImage:
                    "linear-gradient(to top , rgba(255,255,255,0), rgba(255,255,255, 1) 99%)",
            }}
        >
            <div
                id="tech-cards-container"
                className="bt-4 absolute top-0 z-[-1] flex items-start justify-center space-x-4 lg:space-x-8"
            >
                {new Array(51).fill(0).map((_, i) => (
                    <div
                        key={i}
                        className={` ${
                            i % 2 === 0 ? "-mt-20" : i % 3 === 0 ? "-mt-10" : ""
                        } flex flex-col items-center justify-center space-y-4 lg:space-y-8`}
                    >
                        {shuffleArray(techLogos).map((_, i) => (
                            <Card
                                key={i}
                                big={randomTrueFalse()}
                                medium={randomTrueFalse()}
                            >
                                <img
                                    src={_.logo}
                                    alt={_.alt}
                                    className="h-full w-full p-0.5 lg:p-2"
                                />
                            </Card>
                        ))}
                    </div>
                ))}
            </div>
            {children}
            {/* <GlassContainer
        blur={8}
        id="projects-list-container"
        className="flex rounded-xl h-[85vh] lg:h-[78vh] w-[88.5vw] flex-col items-center justify-center space-y-10 lg:w-2/3 lg:p-8 py-10"
      >
        <h1 className="text-2xl inline-block lg:text-4xl px-2 lg:px-0 font-semibold font-roslin text-center lg:text-start text-gray-900">
          Things I've Helped Become{" "}
          <div className="inline-block">
            Better
            <div
              className="w-0 border-t-2 border-gray-900"
              id="tech-stack-underline"
            />
          </div>
        </h1>
        <ProjectsList />
      </GlassContainer> */}
        </div>
    );
}

const Card = ({ big, medium, children }) => {
    return (
        <div
            className={`${
                big ? "h-28 lg:h-36" : medium ? "h-24 lg:h-32" : "h-12 lg:h-24"
            } flex w-16 lg:w-20 items-center justify-center rounded-xl border-2 border-gray-500 bg-gray-50 p-2  shadow-xl`}
            style={
                {
                    // background: generateRandomGradient(),
                }
            }
        >
            {children}
        </div>
    );
};

const randomTrueFalse = () => Math.random() > 0.5;
const generateRandomGradient = () => {
    const colors = [
        "red",
        "blue",
        "green",
        "yellow",
        "purple",
        "pink",
        "orange",
    ];
    const randomColor = () => colors[Math.floor(Math.random() * colors.length)];
    return `linear-gradient(to right, ${randomColor()}, ${randomColor()})`;
};

const shuffleArray = (arr) => {
    const copy = [...arr];
    for (let i = copy.length - 1; i > 0; i--) {
        const randomIndex = Math.floor(Math.random() * i);
        const temp = copy[i];
        copy[i] = copy[randomIndex];
        copy[randomIndex] = temp;
    }
    return copy;
};
